import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Typography } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "typography"
    }}>{`Typography`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Typography from 'mfcl/Typography'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Wrap text in the Typography component and give it a variant for the correct style and tags. Custom tags
can also be passed to the component.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Typography} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Component>\n  <Typography variant=\"h1\">h1 - Hello World</Typography>\n  <Typography variant=\"h2\">h2 - Hello World</Typography>\n  <Typography variant=\"h3\">h3 - Hello World</Typography>\n  <Typography variant=\"h4\">h4 - Hello World</Typography>\n  <Typography variant=\"h5\">h5 - Hello World</Typography>\n  <Typography variant=\"h6\">h6 - Hello World</Typography>\n  <Typography variant=\"paragraph-lg\">paragraph-lg - Hello World</Typography>\n  <Typography variant=\"paragraph\">paragraph - Hello World</Typography>\n  <Typography variant=\"paragraph-sm\">paragraph-sm - Hello World</Typography>\n  <Typography variant=\"price-lg\">price-lg - Hello World</Typography>\n  <Typography variant=\"price-sale\">price-sale - Hello World</Typography>\n  <Typography variant=\"price\">price - Hello World</Typography>\n  <Typography variant=\"eyebrow\">eyebrow - Hello World</Typography>\n  <Typography variant=\"byline\">byline - Hello World</Typography>\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Typography,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component mdxType="Component">
    <Typography variant="h1" mdxType="Typography">h1 - Hello World</Typography>
    <Typography variant="h2" mdxType="Typography">h2 - Hello World</Typography>
    <Typography variant="h3" mdxType="Typography">h3 - Hello World</Typography>
    <Typography variant="h4" mdxType="Typography">h4 - Hello World</Typography>
    <Typography variant="h5" mdxType="Typography">h5 - Hello World</Typography>
    <Typography variant="h6" mdxType="Typography">h6 - Hello World</Typography>
    <Typography variant="paragraph-lg" mdxType="Typography">paragraph-lg - Hello World</Typography>
    <Typography variant="paragraph" mdxType="Typography">paragraph - Hello World</Typography>
    <Typography variant="paragraph-sm" mdxType="Typography">paragraph-sm - Hello World</Typography>
    <Typography variant="price-lg" mdxType="Typography">price-lg - Hello World</Typography>
    <Typography variant="price-sale" mdxType="Typography">price-sale - Hello World</Typography>
    <Typography variant="price" mdxType="Typography">price - Hello World</Typography>
    <Typography variant="eyebrow" mdxType="Typography">eyebrow - Hello World</Typography>
    <Typography variant="byline" mdxType="Typography">byline - Hello World</Typography>
  </Component>
    </Playground>
    <h3 {...{
      "id": "pass-a-custom-tag-to-the-component"
    }}>{`Pass a Custom Tag to the Component`}</h3>
    <p>{`You may want the h1 variant, but you already have an h1 on the page,
so you're able to pass a tag with the tag prop.`}</p>
    <ul>
      <li parentName="ul">{`For example, turn an h2 variant into a p tag.`}</li>
    </ul>
    <Playground __position={2} __code={'<Typography variant=\"h2\" tag=\"p\">\n  This is the h2 variant but rendered as a p tag instead!\n</Typography>\n<Typography variant=\"price-sale\" tag=\"a\" href=\"/\">\n  This is the price-sale variant but rendered as an a tag instead!\n</Typography>\n<Typography\n  variant=\"paragraph-lg\"\n  tag=\"h3\"\n  className=\"veryGreen\"\n  style={{ color: \'green\' }}\n>\n  This is the paragraph-lg variant but rendered as an h3 tag instead! Also\n  with a custom className and styling.\n</Typography>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Typography,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Typography variant="h2" tag="p" mdxType="Typography">
    This is the h2 variant but rendered as a p tag instead!
  </Typography>
  <Typography variant="price-sale" tag="a" href="/" mdxType="Typography">
    This is the price-sale variant but rendered as an a tag instead!
  </Typography>
  <Typography variant="paragraph-lg" tag="h3" className="veryGreen" style={{
        color: 'green'
      }} mdxType="Typography">
    This is the paragraph-lg variant but rendered as an h3 tag instead! Also with a custom className and styling.
  </Typography>
    </Playground>
    <h3 {...{
      "id": "align-the-text-with-the-align-prop"
    }}>{`Align the text with the align prop`}</h3>
    <Playground __position={3} __code={'<Typography variant=\"paragraph-lg\" align=\"left\">\n  I\'m left aligned! This is the default alignment.\n</Typography>\n<Typography variant=\"paragraph-lg\" align=\"center\">\n  I\'m center aligned!\n</Typography>\n<Typography variant=\"paragraph-lg\" align=\"right\">\n  I\'m right aligned!\n</Typography>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Typography,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Typography variant="paragraph-lg" align="left" mdxType="Typography">
    I'm left aligned! This is the default alignment.
  </Typography>
  <Typography variant="paragraph-lg" align="center" mdxType="Typography">
    I'm center aligned!
  </Typography>
  <Typography variant="paragraph-lg" align="right" mdxType="Typography">
    I'm right aligned!
  </Typography>
    </Playground>
    <h3 {...{
      "id": "the-nowrap-prop"
    }}>{`The noWrap Prop`}</h3>
    <p>{`You can pass the noWrap prop and the text will not wrap and instead truncate with a text overflow ellipsis.`}</p>
    <Playground __position={4} __code={'<div style={{ width: \'15%\' }}>\n  <Typography variant=\"paragraph-lg\" noWrap>\n    This is what it\'s like to have noWrap on\n  </Typography>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Typography,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        width: '15%'
      }}>
    <Typography variant="paragraph-lg" noWrap mdxType="Typography">
      This is what it's like to have noWrap on
    </Typography>
  </div>
    </Playground>
    <h3 {...{
      "id": "set-the-color-of-the-text"
    }}>{`Set the color of the text`}</h3>
    <Playground __position={5} __code={'<Typography variant=\"paragraph-lg\" color=\"primary\">\n  The default text color is primary\n</Typography>\n<Typography variant=\"paragraph-lg\" color=\"secondary\">\n  The is the secondary color\n</Typography>\n<Typography variant=\"paragraph-lg\" color=\"success\">\n  The is the success color\n</Typography>\n<Typography variant=\"paragraph-lg\" color=\"alert\">\n  This is the alert color\n</Typography>\n<Typography variant=\"paragraph-lg\" color=\"error\">\n  This is the error color\n</Typography>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Typography,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Typography variant="paragraph-lg" color="primary" mdxType="Typography">
    The default text color is primary
  </Typography>
  <Typography variant="paragraph-lg" color="secondary" mdxType="Typography">
    The is the secondary color
  </Typography>
  <Typography variant="paragraph-lg" color="success" mdxType="Typography">
    The is the success color
  </Typography>
  <Typography variant="paragraph-lg" color="alert" mdxType="Typography">
    This is the alert color
  </Typography>
  <Typography variant="paragraph-lg" color="error" mdxType="Typography">
    This is the error color
  </Typography>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      